// Se utiliza en Home
// Se utiliza en Contacto
// Se utiliza en ChatBot

const Footer = () => {
  return (
    <footer id="footer">
      <div className="center">
        <p>Mrln Tech Works. 2020 - 2024</p>
      </div>
    </footer>
  );
};

export default Footer;
